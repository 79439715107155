import React from 'react';
// import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import XIcon from '@mui/icons-material/X';

const Footer = () => {
  return (
    <footer className="footer">
    <div className="footer-content">
      <div className="footer-section">
        <h3>Quick Links</h3>
        <ul>
          <li><a href="https://evidencer.freeweb3.com" target="_blank" rel="noopener noreferrer">Evidencer</a></li>
          <li><a href="https://freeweb3.com" target="_blank" rel="noopener noreferrer">SmartLaw</a></li>
          <li><a href="https://artifacts.freeweb3.com" target="_blank" rel="noopener noreferrer">Court-Ready Digital Evidence</a></li>
          <li><a href="https://web3bits" target="_blank" rel="noopener noreferrer">3D Claim Resolution & Courthouse</a></li>
          <li><a href="https://lexautomatico.com" target="_blank" rel="noopener noreferrer">LexAutomatico</a></li>
          <li><a href="https://web3legaltools.com" target="_blank" rel="noopener noreferrer">Web3LegalTools</a></li>
        </ul>
      </div>
      <div className="footer-section">
        {/* <h3>Follow Us</h3> */}
        <br/><br/><br/><br/>
<Box p={2} ml={5} >
        <div className="social-icons">
  </div>
      </Box>

      </div>
      <div className="footer-section">
        <h3>Contact Us</h3>
        <p>Email: admin@onelaw.us</p>
        <p>Phone: +1.949.371.6333</p>
    <a href="https://x.com/tenfinney" target="_blank" rel="noopener noreferrer" style={{ color: 'khaki' }}><XIcon></XIcon></a>
    <a href="https://github.com/tenfinney" target="_blank" rel="noopener noreferrer" style={{ color: 'khaki' }}><GitHubIcon></GitHubIcon></a>
    <a href="https://www.linkedin.com/in/scott-stevenson-jd/" target="_blank" rel="noopener noreferrer" style={{ color: 'khaki' }}><LinkedInIcon></LinkedInIcon></a>

            <p>&copy;2024 Web3 Legal Engineering</p>

      </div>
    </div>
          
      </footer>
  );
}

export default Footer;