import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './components/HomePage';
// import AboutPage from './components/AboutPage';
// import ServicesPage from './components/ServicesPage';
// import ContactPage from './components/ContactPage';
// import Header from './components/Header';
import Footer from './components/Footer';
import './App.css';

function App() {
  return (
    <Router>
            <div className="app-container"> 
            {/* <div style={{ backgroundColor: 'khaki' }}> Apply background color */}
      {/* <Header /> */}
      <Routes>
        <Route exact path="/" element={<HomePage />} />
        {/* <Route path="/about" element={<AboutPage />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/contact" element={<ContactPage />} /> */}
      </Routes>
      <Footer />
      </div>
    </Router>
  );
}

export default App;