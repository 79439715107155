/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState } from 'react';
// import { Link, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './HomePage.css';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

const HomePage = () => {



  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };


  const [currentWebsiteIndex, setCurrentWebsiteIndex] = useState(0);
  const [showMenu, setShowMenu] = useState(false);
  const websites = [
    {
      image: 'https://freeweb3.infura-ipfs.io/ipfs/QmeXeUirWCB84ow9tvjTEUtXtZurua8LgRmCBKZBCNnvXt',
      imagelg: 'https://freeweb3.infura-ipfs.io/ipfs/Qmf4RgSeaGAHZtueUrTX3fko8ArvY76MPSo5uQ3xSBMuRq',
      title: '1. Identity',
      description: 'This form establishes and verifies the identity of claimants and other involved parties',
      url: 'https://freeweb3.infura-ipfs.io/ipfs/QmeJ9dsUVMGRvxyRPtXdnquXwNadHzfB5u3ANcfkERTFeh',
    },
    {
      image: 'https://freeweb3.infura-ipfs.io/ipfs/QmPgbDB5cs3v88KgbaE7E4mmoa82oGznS6n1PTHyQcFjQv',
      imagelg: 'https://freeweb3.infura-ipfs.io/ipfs/QmUbUbsH7gaThEUE7zEBrCbbMepxKS5dAh7ETNS3g1LYTy',
      title: '2. ESI Upload',
      description: 'This form facilitates the electronic submission of evidence and supporting documents',
      url: 'https://freeweb3.infura-ipfs.io/ipfs/QmWiqY1pvo3oxs4X8PpzaYsmFZNnx41TyucuiDTaqdbpv7',
    },
    {
      image: 'https://freeweb3.infura-ipfs.io/ipfs/QmckLm8KmN7UoiH4N1o56XAYesV4BjYQWZqjaix3hghqwi',
      imagelg: 'https://freeweb3.infura-ipfs.io/ipfs/QmVdWBFN5i6CW5MFsEvswGuCywJX36eUS4iPJ738bJZMR6',
      title: '3. Claimant Verification',
      description: 'This form is used to verify the identity and eligibility of claimants for processing',
      url: 'https://freeweb3.infura-ipfs.io/ipfs/QmZTW65PNa5SEX7ke3ZpswMeKPaC8HTqfCNsqGDucf7uCH',
    },
    {
      image: 'https://freeweb3.infura-ipfs.io/ipfs/QmZa1w7GNLxN66Zk2qDzHTvfmyNWQiYcv98jn8VHD3HfNe',
      imagelg: 'https://freeweb3.infura-ipfs.io/ipfs/QmSFzjvsxRaoXwhJHAC7djXcAeXk26NUpMmRoQHMRJzVDC',
      title: '4. Party Assignment',
      description: 'This for assigns roles and responsibilities to involved parties in the claim process',
      url: 'https://freeweb3.infura-ipfs.io/ipfs/QmfB9GMT4H4hbFrfB3jejyrTs41Ks6C2TuCZGbApHNkRSg',
    },
    {
      image: 'https://freeweb3.infura-ipfs.io/ipfs/QmPPL3cymygm4JXyJqGMQCwzkpVQVT4t5KLqhhCiPrCur2',
      imagelg: 'https://freeweb3.infura-ipfs.io/ipfs/QmXL9gU3vTKCaTmznXn4G2AvzT1dKkzm6pzUfFWVWSyxQY',
      title: '5. Claim-initiation',
      description: 'This form initiates the formal process of filing a claim within the system',
      url: 'https://freeweb3.infura-ipfs.io/ipfs/QmeandRv1vZgyQCWnczuads5ScXNa6NZe5ZhMDyjcDiymJ',
    },


    {
      image: 'https://freeweb3.infura-ipfs.io/ipfs/QmZvkC9RW7pDEc8Jih7jEyV57NfjLNJVobHRfxeT3Lz5Us',
      imagelg: 'https://freeweb3.infura-ipfs.io/ipfs/QmfSeqiaFtuw6GucxiL8tXrzp3eWde2g4Ki8hsDw3BDLE9',
      title: '6. ESI to Claim',
      description: 'This form integrates electronically stored information (ESI) into the claim record',
      url: 'https://freeweb3.infura-ipfs.io/ipfs/QmY7SfVBnQnhL3ET6ng6yem46D6MvChHPzEkktnHN5fta9',
    },
    {
      image: 'https://freeweb3.infura-ipfs.io/ipfs/QmU4zFErwUfFWRgys8XUWRZm7H3wnnvQxB2BpvXvTovRBB',
      imagelg: 'https://freeweb3.infura-ipfs.io/ipfs/QmYkoMKrAKCAsFgnYKPkRxLQFNQVJCqCEwkAF1LYBb5tY7',
      title: '7. Claim Queue',
      description: 'This form  manages and prioritizes claims within a processing queue.',
      url: 'https://freeweb3.infura-ipfs.io/ipfs/QmeYB4JUWg1VebAWb3iYMCunxhu18kfdoDNVfmHn6v6nSf',
    },
    {
      image: 'https://freeweb3.infura-ipfs.io/ipfs/QmUMitUghXX5z9HankjWBtKBgHgNkiWKpufQn3c6pek5M9',
      imagelg: 'https://freeweb3.infura-ipfs.io/ipfs/Qma84hM4jp2tDYqS3sErTkxb9BuEHjMhi2PHqMyFkS6kJi',
      title: '8. Jurisdiction',
      description: 'Determines the appropriate jurisdiction for handling the claim based on legal considerations',
      url: 'https://freeweb3.infura-ipfs.io/ipfs/QmYUdKTRbfLz6mCfWvxypVncwneaFVZByeXPaa2tmhtCYw',
    },
    {
      image: 'https://freeweb3.infura-ipfs.io/ipfs/QmeMDUVtbeEtBet89YUnBbgqmfg6cScZeZAdjJvUKmJemh',
      imagelg: 'https://freeweb3.infura-ipfs.io/ipfs/QmagC77aktgUSHbE3WVz8Dwb3Stav2meiemuh68UvWEchv',
      title: '9. Claim Attestations',
      description: 'This form provides a mechanism for parties to attest to the accuracy and validity of claim information',
      url: 'https://freeweb3.infura-ipfs.io/ipfs/QmNpBuig2QGA4fnjnG3wtd24eTfSGaZUyKhdxRdBxoz4m8',
    },
    {
      image: 'https://freeweb3.infura-ipfs.io/ipfs/QmSfciBxXjywdHFEa5P2Z7oqZoU4VhbUpmiKcwyph2Qd5A',
      imagelg: 'https://freeweb3.infura-ipfs.io/ipfs/QmVshXNPv7KwbTPpvGSqULD9CromZkHrZUudS68e8XxtSW',
      title: '10. Damages',
      description: 'THis form calculates and assesses the extent of damages or losses incurred',
      url: 'https://freeweb3.infura-ipfs.io/ipfs/QmWtiSS18vKpw79Mt8du8LA56aWn4bReWASnvsE7VxyyTP',
    },


    {
      image: 'https://freeweb3.infura-ipfs.io/ipfs/QmP2nC2Eto4E3ZHdqzjtYnPNeZNsroQqx1Z3kAWtwEQ3SK',
      imagelg: 'https://freeweb3.infura-ipfs.io/ipfs/QmSAYjsuioz7Mi1XiCm6URf9oNy2UyoRypADAjz45gwrVv',
      title: '11. Purchase a Claim Interest',
      description: 'This form tracks and manages any interest or investments related to the claim.',
      url: 'https://freeweb3.infura-ipfs.io/ipfs/QmWtiSS18vKpw79Mt8du8LA56aWn4bReWASnvsE7VxyyTP',
    },
    {
      image: 'https://freeweb3.infura-ipfs.io/ipfs/QmTnLw5A58ztWgEo8H3tcE7qAtVQxPAP9ncAVFRzJ327oj',
      imagelg: 'https://freeweb3.infura-ipfs.io/ipfs/QmPcc9UZjvBEsa9inPxAdQnMoc39MksJhfLzqxMspmGE4S',
      title: '12. Claim Resolution',
      description: 'This form facilitates the resolution of claims through negotiation, arbitration, or adjudication.',
      url: 'https://freeweb3.infura-ipfs.io/ipfs/QmdnXRbDfZdLhFRbYJQcouHAuvYZjuboMtjRr7maVyTWsb',
    },
    // {
    //   image: 'https://freeweb3.infura-ipfs.io/ipfs/QmTr2LF54ioNxwGp94vZSv9taJGyKnuX4LhZcyDXXc8T7g',
    //   imagelg: 'https://freeweb3.infura-ipfs.io/ipfs/QmQU9KhPCyH7UQxJG4bQWnU4c4RtkBDto81kFKHzk6ZQzE',
    //   title: '13. Claim Interest Reconciliation',
    //   description: 'This form resolves a claim interest',
    //   url: 'https://freeweb3.infura-ipfs.io/ipfs/QmeZhFFzMwJSScDrjYfcRRp8KmepZAEHcPsHocQjVkVndZ',
    // }
  ];

  // const navigate = useNavigate();

  const handleNext = () => {
    setCurrentWebsiteIndex((prevIndex) => (prevIndex + 1) % websites.length);
  };

  const handlePrevious = () => {
    setCurrentWebsiteIndex((prevIndex) =>
      prevIndex === 0 ? websites.length - 1 : prevIndex - 1
    );
  };

  // const handleVisit = () => {
  //   const selectedWebsite = websites[currentWebsiteIndex];
  //   navigate(`/website/${selectedWebsite.title}`);
  // };

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };



  return (
    <div>
 
 
 <header>


 <Grid container alignItems="center" justifyContent="space-between" wrap="nowrap">
    <Grid item>
      <Link to="https://evidencer.freeweb3.com" target="_blank">
        <img
          // src="https://freeweb3.infura-ipfs.io/ipfs/QmR5EToJ6SHZrH2PL4VzjBEHbCirNXbLQHxm4vaShGKxB1"
          // src="https://freeweb3.infura-ipfs.io/ipfs/QmVaC94oitTdBzeq2sK7598xK328MwsPAahPaqoU84ZXqc"
          src="https://freeweb3.infura-ipfs.io/ipfs/QmedVESdKpAazCbHvqDd5JFLmc7XDZkUgvGuyFiBLrQfoD"
          alt="logo"
          style={{ width: '400px', height: 'auto' }}
        />
      </Link>
    </Grid>
    <Grid item xs={12} sm={6}> {/* Show the image up to medium screen size */}
      {/* <Link to="https://evidencer.freeweb3.com">
        <img
          src="https://freeweb3.infura-ipfs.io/ipfs/QmP9tW2r473BdCPGGtK8pY73Eo4zLcNbjE25eGEM13wLYt"
          alt="evidencer"
          style={{ width: '500px', height: '114px' }}
        />
      </Link> */}
    </Grid>











    <Grid container>
    <Grid item>
      <KeyboardDoubleArrowLeftIcon style={{ width: '50px', height: '50px' }} onClick={toggleMenu}>
      </KeyboardDoubleArrowLeftIcon>
      {showMenu && (
        <div className="hamburger-menu">
          <ul>
            {websites.map((website, index) => (
              <li key={index}>
                <Link to={website.url}>{website.title}</Link>
              </li>
            ))}
          </ul>
        </div>
      )}
    </Grid>
    <Grid item>
      <Box display="flex" justifyContent="center" alignItems="center" marginBottom={2} flexDirection="column">
        <div>
          <Link to="https://freeweb3.infura-ipfs.io/ipfs/QmXNsmGhsyKS453XEE9gNzKbLSykRsP9nWTJ4fHA8B1V3i" target="_blank">
            <Button variant="contained" color="primary" style={{ width: '150px', marginRight: `10px` }}>
              Mnemonic Formation
            </Button>
          </Link>
        </div>
      </Box>
    </Grid>
    <Grid item>
      <MenuIcon style={{ width: '50px', height: '50px' }} onClick={toggleMenu}>
      </MenuIcon>
      {showMenu && (
        <div className="hamburger-menu">
          <ul>
            {websites.map((website, index) => (
              <li key={index}>
                <Link to={website.url}>{website.title}</Link>
              </li>
            ))}
          </ul>
        </div>
      )}
    </Grid>
    <Grid item>
      <Box display="flex" justifyContent="center" alignItems="center" marginBottom={2} flexDirection="column">
        <div>
          <Link to="https://freeweb3.infura-ipfs.io/ipfs/QmZAECQqGHQxDoQKNQ3dLCmFvTxrthpGgtLS5mgeVm2LDY" target="_blank">
            <Button variant="contained" color="success" style={{ width: '150px', marginLeft: `10px` }}>
              Mnemonic Testing
            </Button>
          </Link>
        </div>
      </Box>
    </Grid>
    <Grid item style={{ marginRight: '20px' }}>
      <KeyboardDoubleArrowRightIcon style={{ width: '50px', height: '50px' }} onClick={toggleMenu}>
      </KeyboardDoubleArrowRightIcon>
      {showMenu && (
        <div className="hamburger-menu">
          <ul>
            {websites.map((website, index) => (
              <li key={index}>
                <Link to={website.url}>{website.title}</Link>
              </li>
            ))}
          </ul>
        </div>
      )}
    </Grid>
  </Grid>

  </Grid>

</header>



      <Box p={2} className="outer-box">
        <Grid
          container
          spacing={4}
          className="site-display"
          bgcolor="lavender"
          p={4}
          border={1}
          borderColor="primary.main"
          borderRadius={4}
        >
          {/* Left Grid item */}
          <Grid item xs={12} md={6} style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ width: '90%' }}>
              <h1>Evidencer Claim Management System</h1>
              <h2>{websites[currentWebsiteIndex].title}</h2>
              <img
                src={websites[currentWebsiteIndex].imagelg}
                alt={websites[currentWebsiteIndex].title}
                style={{ width: '100%', height: 'auto' }}
              />
            </div>
          </Grid>

          {/* Right Grid item */}
          <Grid item xs={12} md={6} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>





            <Box>
              <iframe
                src={websites[currentWebsiteIndex].url}
                title={websites[currentWebsiteIndex].title}
                style={{ width: '100%', height: '800px', border: 'none', marginTop: '20px', borderRadius: '8px', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' }}
              ></iframe>
            </Box>

            <Box>
              <div style={{ padding: '20px', marginTop: '20px', border: '1px solid #ccc', borderRadius: '8px', backgroundColor: 'seashell' }}>
                <h2>{websites[currentWebsiteIndex].title}&nbsp;Form Description</h2>
                <p style={{ marginBottom: '20px', fontSize: '16px', lineHeight: '1.5' }}>{websites[currentWebsiteIndex].description}</p>
                <Button variant="outlined" color="primary" style={{ marginRight: '10px', textTransform: 'none' }} onClick={handlePrevious}>
                  Previous
                </Button>
                <a href={websites[currentWebsiteIndex].url} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                  <Button variant="contained" color="primary" style={{ marginRight: '10px', textTransform: 'none' }}>
                    Visit Site
                  </Button>
                </a>
                <Button variant="outlined" color="primary" style={{ marginRight: '10px', textTransform: 'none' }} onClick={handleNext}>
                  Next
                </Button>
                {/* <Button variant="outlined" color="primary" style={{ textTransform: 'none' }} onClick={handleVisit}>
        View Details
      </Button> */}
              </div>
            </Box>



          </Grid>

        </Grid>
      </Box>



      <Box p={2} bgcolor="lavender" className="outer-box">
        <Box className="custom-slider" p={2} border={1} borderColor="primary.main" borderRadius={4}>
          <div className="website-grid">
            {websites.map((website, index) => (
              <div className="website-item" key={index}>
                <h3>{website.title}</h3>
                <a href={website.url} target="_blank" rel="noopener noreferrer">
                  <img src={website.image} alt={website.title} style={{ width: '280px', height: '160px' }} />
                </a>
                <p>{website.description}</p>
                <a href={website.url} target="_blank" rel="noopener noreferrer" style={{ fontSize: '8pt' }}>
                  {website.url}
                </a>
              </div>
            ))}
          </div>
        </Box>
      </Box>



      <Box p={2} className="outer-box">
        <Box className="custom-slider" bgcolor="lavender" p={2} border={1} borderColor="primary.main" borderRadius={4}>
          <Slider {...settings}>
            {websites.map((website, index) => (
              <div className="slider-item" key={index} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <h3>{website.title}</h3>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <a href={website.url} target="_blank" rel="noopener noreferrer">
                    <img src={website.imagelg} alt={website.title} style={{ width: '500px', height: '286px' }} />
                  </a>
                </div>
                <p>{website.description}</p>
                <a href={website.url} target="_blank" rel="noopener noreferrer" style={{ fontSize: '8pt' }}>
                  {website.url}
                </a>
              </div>
            ))}
          </Slider>
          <p className="slider-use-description">
            Slide left and right or use your arrow keys to view the forms.
          </p>
        </Box>
      </Box>

    </div>


  );
};

export default HomePage;
